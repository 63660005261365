import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.textForClipboard = this.element.dataset.textForClipboard;
    this.element.addEventListener(
      'click',
      function () {
        navigator.clipboard.writeText(this.textForClipboard);
      }.bind(this)
    );
  }
}

import { Controller } from '@hotwired/stimulus';

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

export default class extends Controller {
  connect() {
    this.baseEventsUrl = this.element.dataset.baseEventsUrl;
    this.calendar = new Calendar(this.element, {
      firstDay: 1,
      plugins: [dayGridPlugin, listPlugin, timeGridPlugin, bootstrap5Plugin],
      themeSystem: 'bootstrap5',
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek',
      },
      events: this.loadEvents.bind(this),
      eventClick: this.eventClick.bind(this),
    });
    this.calendar.render();
  }

  eventClick(event) {
    if (event.event.url) {
      event.jsEvent.preventDefault();
      window.open(event.event.url, '_blank');
    }
  }

  async loadEvents({ start, end, timezone, callback }) {
    const eventsUrl = `${
      this.baseEventsUrl
    }?start_date=${start.toISOString()}&end_date=${end.toISOString()}`;
    const r = await fetch(eventsUrl);
    const events = await r.json();
    return events;
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element
      .querySelectorAll('select')
      .forEach((e) =>
        e.addEventListener('change', (e) =>
          e.currentTarget.form.requestSubmit()
        )
      );
    this.element
      .querySelectorAll('input')
      .forEach((e) =>
        e.addEventListener('change', (e) =>
          e.currentTarget.form.requestSubmit()
        )
      );
  }
}

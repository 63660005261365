import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['firstName', 'lastName', 'initials'];

  connect() {
    [this.firstNameTarget, this.lastNameTarget].forEach((e) =>
      e.addEventListener('change', this.nameChanged.bind(this))
    );
  }

  nameChanged() {
    if (
      this.firstNameTarget.value &&
      this.lastNameTarget.value &&
      !this.initialsTarget.value
    ) {
      this.initialsTarget.value =
        `${this.firstNameTarget.value[0]}${this.lastNameTarget.value[0]}`.toUpperCase();
    }
  }
}

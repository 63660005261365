import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

export default class extends Controller {
  connect() {
    this.fp = flatpickr(this.element, {
      altInput: true,
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d',
    });
  }

  disconnect() {
    if (this.fp) {
      this.fp.destroy();
    }
  }
}
